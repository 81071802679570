// recoil
import { channelInfoState, deviceInfoState, ocrState } from 'src/recoil/recoil';
import { useRecoilValue, useResetRecoilState } from 'recoil';

// libraries
import { useNavigate } from 'react-router-dom';

// constants
import { CHANNEL_CODE, RESULT, RivConstant } from 'src/constants/rivConstant';
import { paths } from 'src/routes/path';

// utils
import { NativeBridge } from 'src/utils/nativeBridge';
import { useCalledBy } from 'src/hooks/use-called-by';
import { useCallback } from 'react';

export function useClose() {
  const channelInfo = useRecoilValue(channelInfoState);
  const resetDeviceInfoState = useResetRecoilState(deviceInfoState);
  const resetChannelInfoState = useResetRecoilState(channelInfoState);
  const resetOcrInfoState = useResetRecoilState(ocrState);
  const navigate = useNavigate();
  const { tmmCloseSend } = useCalledBy();

  /**
   * 데이터 클린 / 토큰 제거
   * @param removeToken (true: 토큰제거, false, 토큰유지)
   * */
  const cleanData = useCallback(
    (removeToken) => {
      resetOcrInfoState();
      if (removeToken) {
        resetDeviceInfoState();
        resetChannelInfoState();
        sessionStorage.removeItem(RivConstant.ACCESS_TOKEN_KEY);
        sessionStorage.removeItem(RivConstant.REQUEST_ID);
      }
    },
    [resetDeviceInfoState, resetChannelInfoState, resetOcrInfoState]
  );

  /**
   * @deprecated
   * 신분증 사진 캔버스 데이터 초기화 (금보원 심사 통과 위해 필요)
   * 금보원 심사로 인해 마스킹 신분증 사진 제거로 deprecate
   **/
  const cleanCanvas = () => {
    const canvas = document.getElementById('resultCanvas');
    if (canvas) {
      const ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    }
  };

  /**
   * 웹뷰 화면 close
   * @param result (타 채널에 브릿지 통신으로 결과 전송 필요시에 사용. 성공시에 'Y', 기본 값 'N')
   * */
  const webClose = useCallback(
    (result = RESULT.FAIL) => {
      cleanData(true);
      // 보이는 TM 페이지 닫기 메시지 전송
      tmmCloseSend();

      if (channelInfo.channel === CHANNEL_CODE.HCI && (window.AndroidBridge || window.webkit)) {
        // 한화생명 앱 안면인식 결과 전송 브릿지 통신 call
        const saveDataArgs = {
          key: 'livenessCompleted',
          value: result
        };
        NativeBridge.call('common', 'saveData', saveDataArgs, 'callbackSaveData');
        // 한화생명 앱 화면 닫기 브릿지 통신 call
        NativeBridge.call('wallet', 'webClose', {}, 'callbackNativeResponse');
      }

      // webClose postMessage 전달
      if (window.opener) {
        const message = {
          command: 'webClose',
          args: {
            result: result === RESULT.SUCCESS_JUMIN || result === RESULT.SUCCESS_DRIVER ? RESULT.SUCCESS : RESULT.FAIL
          }
        };
        const encodedMessage = encodeURIComponent(JSON.stringify(message));
        window.opener.postMessage(encodedMessage, '*');
      }
      window.close();
      navigate(paths.home);
    },
    [navigate, cleanData, tmmCloseSend]
  );

  /*saveData 콜백함수*/
  window.callbackSaveData = useCallback((response) => {
    console.log('===== [callbackSaveData 호출] =====');
    if (response) {
      const jsonResponse = decodeURIComponent(response);
      const parsedResponse = JSON.parse(jsonResponse);
      console.log('===== [callbackSaveData] ====== parsedResponse: ', parsedResponse);
    }
  }, []);

  return { cleanData, webClose };
}
